import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 64 64"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M32.001 24A2 2 0 0 0 30 26v27.171l-4.585-4.585a1.998 1.998 0 1 0-2.827 2.828l8 8c.391.391.902.586 1.414.586s1.023-.195 1.414-.586l8-8a2 2 0 1 0-2.828-2.828L34 53.173V26c-.002-1-.895-2-1.999-2" }, null, -1),
    _createElementVNode("path", { d: "M62 4H2a2 2 0 0 0-2 2v36a2 2 0 0 0 2 2h22a2 2 0 0 0 0-4H4V8h56v32H40a2 2 0 0 0 0 4h22a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2" }, null, -1)
  ])))
}
export default { render: render }