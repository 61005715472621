import { createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "5 5 40 40"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("defs", null, [
      (_openBlock(), _createBlock(_resolveDynamicComponent("style"), null, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode(".c{fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:3px}")
        ])),
        _: 1
      }))
    ]),
    _cache[1] || (_cache[1] = _createElementVNode("path", {
      d: "M25.905 34.047c5.043-1.472 6.82-1.298 8.349-1.294 1.134.003 2.258-.203 3.292-.67l.133-.06c4.477-2.021 6.181-7.503 3.631-11.7-3.846-6.33-9.16-14.018-11.98-13.42-4.74 1.006-4.74 7.122-4.74 7.122s-1.271-.278-4.337.672c-3.094.85-4.017 1.768-4.017 1.768s-3.292-5.154-7.828-3.45c-2.697 1.012-3.04 10.351-2.873 17.757.11 4.91 4.497 8.612 9.357 7.906l.145-.02c1.122-.164 2.181-.596 3.135-1.208 1.287-.827 2.69-1.93 7.733-3.403Z",
      class: "c"
    }, null, -1)),
    _cache[2] || (_cache[2] = _createElementVNode("path", {
      d: "M16 38.449c1.511 3.138 3.737 3.16 5.264 1.901 1.527-1.258 1.64-4.019-.023-6.564M24.48 30.837c2.429-.71 10.226-.66 7.968-6.387s-5.869-5.863-11.174-4.585c-5.159 1.778-8.128 3.836-6.949 9.878 1.18 6.043 7.725 1.804 10.154 1.094M19.853 28.218l-.956-3.274M26.968 26.14l-.956-3.274M34.708 32.74c2.273 3.972 2.226 6.963 2.226 6.963",
      class: "c"
    }, null, -1)),
    _cache[3] || (_cache[3] = _createElementVNode("path", {
      d: "M32.854 32.75c-1.494 4.283-7.256 6.953-12.674 3.472",
      class: "c"
    }, null, -1))
  ]))
}
export default { render: render }